<template>
  <el-container class="home-box">
    <!-- 页面头部 -->
    <el-header>
      <div>
        <img src="../assets/logo.png" alt="logo">
        <span>电商后台管理系统</span>
      </div>
      <el-button type="info" plain @click="logout" size="mini">系统退出</el-button>
    </el-header>
    <!-- 主体区域 -->
    <el-container>
      <!-- 侧边栏 -->
      <el-aside :width="isCollapse ? '64px' : '200px'">
        <div class="toggle-button" @click="toggleCollapse" :title="titleCollapse">|||</div>
        <el-menu :default-active="activePath" background-color="#545c64" text-color="#fff" active-text-color="#409eff" unique-opened :collapse="isCollapse" :collapse-transition="false" :router="true">
          <!-- 一级菜单栏 -->
          <el-submenu :index="index+''" v-for="(item, index) in menuList" :key="item.id">
            <template slot="title">
              <i :class="iconObj[index]" class="iconfont"></i>
              <span>{{item.authName}}</span>
            </template>
            <!-- 二级菜单栏 -->
            <el-menu-item :index="'/'+subItem.path" v-for="subItem in item.children" :key="subItem.id" @click="saveNavState('/'+subItem.path)">
                <i class="el-icon-menu"></i>
                <span>{{subItem.authName}}</span>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <!-- 右侧内容区 -->
      <el-main>
          <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
export default {
  data () {
    return {
      menuList: [],
      isCollapse: false,
      titleCollapse: '单击收起',
      activePath: '',
      iconObj: [
        'icon-users',
        'icon-tijikongjian',
        'icon-shangpin',
        'icon-danju',
        'icon-baobiao'
      ]
    }
  },
  created () {
    // 获取菜单列表
    this.getMenuList()
    // 当刷新页面时，可以获取激活的菜单栏，
    this.activePath = window.sessionStorage.getItem('activePath')
  },
  methods: {
    // 退出系统
    logout () {
      // 1.销毁本地token
      // window.sessionStorage.removeItem('token')
      window.sessionStorage.clear()
      // 2.强制跳转到登录页
      this.$router.push('/login')
    },
    // 获取菜单列表
    async getMenuList () {
      const { data: res } = await this.$http.get('menus')
      // console.log(res)
      if (res.meta.status !== 200) {
        return this.$message({
          type: 'error',
          message: res.meta.msg || '获取菜单列表失败',
          center: true,
          duration: 1000
        })
      }
      // 获取数据成功
      this.menuList = res.data
    },
    // 菜单栏折叠
    toggleCollapse () {
      this.isCollapse = !this.isCollapse
      this.titleCollapse = this.isCollapse ? '单击展开' : '单击收起'
    },
    // 保存导航状态
    saveNavState (activePath) {
      this.activePath = activePath
      // 把导航状态保存到sessionStorage中，当页面刷新的时候就可以从其获取
      window.sessionStorage.setItem('activePath', activePath)
    }
  }
}
</script>
<style lang="less" scoped>
  .home-box{
    height: 100%;
  }
  .el-header{
    background-color: #B3C0D1;
    color: #fff;
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    align-items: center;
    > div{
      display: flex;
      align-items: center;
      font-size: 20px;
      img{
        height: 60px;
      }
      span{
        margin-left: 20px;
      }
    }
  }
  .el-aside {
    background-color: rgb(84, 92, 100);
    .toggle-button{
      line-height: 24px;
      background-color: #4a5064;
      text-align: center;
      font-size: 10px;
      color: #fff;
      cursor: pointer;
      letter-spacing: 0.2em;
    }
    .el-menu{
      border-right: none;
    }
  }
  .el-main {
    background-color: #E9EEF3;
    color: #000;
  }
  .iconfont{
    margin-right: 10px;
  }
</style>
