<template>
  <div class="login-container">
    <div class="login-form">
      <div class="logo">
        <img src="../assets/logo.png" alt="logo">
      </div>
      <!-- 登录表单 -->
      <el-form label-width="0px" :model="loginForm" :rules="loginRules" ref="loginFormRef">
        <el-form-item prop="username">
          <el-input prefix-icon="iconfont icon-user" v-model="loginForm.username"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" prefix-icon="iconfont icon-3702mima" v-model="loginForm.password"></el-input>
        </el-form-item>
        <el-form-item class="btns">
            <el-button type="info" @click="resetLoginForm('loginFormRef')">重置</el-button>
            <el-button type="primary" @click="login('loginFormRef')">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      // 登录表单的数据绑定
      loginForm: {
        username: 'admin',
        password: '123456'
      },
      // 表单验证规则
      loginRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 15, message: '长度在 6 到 15 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    // 重置表单
    resetLoginForm (formName) {
      this.$refs[formName].resetFields()
    },
    // 登录处理
    login (formName) {
      this.$refs[formName].validate(async (valid) => {
        // 依据表单验证规则得到验证结果，valid为true表示通过，false表示不通过
        if (!valid) return false
        // 表单验证规则通过，把数据提交到后台进行验证
        const { data: res } = await this.$http.post('login', this.loginForm)
        console.log(res)
        // 登录成功
        if (res.meta.status === 200) {
          this.$message({
            type: 'success',
            message: res.meta.msg || '登录成功',
            center: true, // 提示消息文字居中显示
            duration: 1000 // 提示消息停留的时间，单位ms
          })
          // 1.把token保存起来
          window.sessionStorage.setItem('token', res.data.token)
          window.sessionStorage.setItem('username', this.loginForm.username)
          // 2. 跳转页面
          this.$router.push('/home')
        } else { // 登录失败
          this.$message({
            type: 'error',
            message: res.meta.msg || '登录失败',
            center: true,
            duration: 1000
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
  .login-container{
    height: 100%;
    background-color: #2b4b6b;
    .login-form{
      width: 450px;
      height: 300px;
      background-color: #fff;
      border-radius:4px;
      position:absolute;
      left:50%;
      top:50%;
      // margin-left:-225px;
      // margin-top:-225px;
      transform: translate(-50%,-50%);
      .logo{
        width: 130px;
        height: 130px;
        background-color: #fff;
        border-radius:50%;
        padding:10px;
        border: 1px solid #eee;
        position:absolute;
        left:50%;
        top:0;
        transform: translate(-50%,-50%);
        box-shadow: 0 0 10px #ddd;
        img{
          width: 100%;
          height: 100%;
          border-radius:50%;
          background-color: #eee;
        }
      }
      .el-form{
        width: 100%;
        box-sizing: border-box;
        position:absolute;
        bottom:0;
        padding: 0 40px;
        .btns{
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
</style>
