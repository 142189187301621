<template>
  <div>
    <h3>欢迎您，{{username}}，{{tip}} 当前时间：{{time | dateFormat}}</h3>
  </div>
</template>
<script>
export default {
  data () {
    return {
      username: '',
      time: '',
      hour: 0,
      calendar: new Date()
    }
  },
  created () {
    this.username = window.sessionStorage.getItem('username')
    this.getCurrentTime()
  },
  methods: {
    getCurrentTime () {
      this.time = new Date()
      this.hour = this.time.getHours()
      setInterval(() => {
        this.time = new Date()
        this.hour = this.time.getHours()
      }, 1000)
    }
  },
  computed: {
    tip () {
      if (this.hour >= 3 && this.hour < 6) return '凌晨了，新的一天开始了！'
      else if (this.hour >= 6 && this.hour < 8) return '早上好！'
      else if (this.hour >= 8 && this.hour < 11) return '上午好！'
      else if (this.hour >= 11 && this.hour < 13) return '中午好！'
      else if (this.hour >= 13 && this.hour < 17) return '下午好！'
      else if (this.hour >= 17 && this.hour < 19) return '傍晚好！'
      else if (this.hour >= 19 && this.hour < 23) return '晚上好！'
      return '深夜了，请注意休息！'
    }
  }
}
</script>
<style lang="less" scoped>
</style>
